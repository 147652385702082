body,html,#root{

  height:100%;
  width:100%;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  color:#999999;

}

#root{
  overflow-y: auto;
  position: relative;
}

#App{
  min-height: 100%;
  width: 100%;
  position: relative;
  height: auto;
}

.ro{border:1px solid red;}

h1,h2,h3,h4,h5{
  font-family: 'Viga', sans-serif;
  margin: 0;
}

#cont-modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
@keyframes loading {
  from{
    background-position:0 center;
  }
  to{background-position:51px center;}
}
